import {
  Alert,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'components/common/ReactToast';
import { useEffect, useRef } from 'react';
import { WAREHOUSE_TYPE } from 'definitions';
import React, { useState } from 'react';
import useToggleModal from 'utils/useToggleModal';

const CreateReceivingModal = (props) => {
  const { isOpenReceivingModal, toggleReceivingModal, receivingStore, currentWarehouse, isSupportRefurbished } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [validateSnResponse, setValidateSnResponse] = useState({});
  const [confirmReceivingResponse, setConfirmReceivingResponse] = useState({});
  const [skipUploadReceiving, setSkipUploadReceiving] = useState(false);
  const [sns, setSns] = useState('');
  const [rows, setRows] = useState([{ sku: '', quantity: '' }]);
  const [warehouseType, setWarehouseType] = useState(WAREHOUSE_TYPE.REGULAR);
  const [confirmFormData, setConfirmFormData] = useState({});
  const [formData, setFormData] = useState({
    sns: [],
    skuMaps: [{}],
    trackingNo: 'ManualReceiving',
    carrier: 'ManualReceiving',
    receivingWarehouse: currentWarehouse,
    remarks: '',
    skipUploadReceiving: skipUploadReceiving,
  });
  const { isOpen, setIsOpen, toggleModal } = useToggleModal({
    InputSnSkuModal: true,
    InputOtherInfoModal: false,
    ReceivingSummaryModal: false,
    ErrorMessageModal: false,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      skipUploadReceiving: skipUploadReceiving,
      receivingWarehouse: currentWarehouse,
    }));
  }, [skipUploadReceiving, currentWarehouse]);

  useEffect(() => {
    if (sns) {
      const snsArray = sns
        .split('\n')
        .map((i) => i.trim())
        .filter((i) => i !== '');
      setFormData((prevData) => ({ ...prevData, sns: snsArray }));
    } else {
      setFormData((prevData) => ({ ...prevData, sns: [] }));
    }
  }, [sns]);

  useEffect(() => {
    const filledRows = rows.filter((row) => (typeof row?.sku === 'string' && row.sku.trim()) || row.quantity);

    setFormData((prevFormData) => ({
      ...prevFormData,
      skuMaps: filledRows,
    }));
  }, [rows]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateSn = async (payload) => {
    return new Promise((resolve) => {
      receivingStore
        .validateSn(payload)
        .then((res) => {
          resolve(res);
          setValidateSnResponse(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const searchSku = async (inputSku) => {
    return new Promise((resolve, reject) => {
      receivingStore
        .searchSku(inputSku)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onSubmitCreateReceiving = async () => {
    let productListMap = new Map();

    confirmFormData.productList?.forEach((item) => {
      const existingItem = productListMap.get(item.sku);

      if (!existingItem) {
        productListMap.set(item.sku, {
          sku: item.sku,
          sns: [...item.sns],
          quantity: item.quantity,
        });
      } else {
        existingItem.sns = [...new Set([...existingItem.sns, ...item.sns])];
        existingItem.quantity += item.quantity;
      }
    });

    return new Promise((resolve, reject) => {
      receivingStore
        .createReceiving({
          ...confirmFormData,
          productList: Array.from(productListMap.values()),
          receivingWarehouseCode: getReceivingWarehouseCode(confirmFormData.receivingWarehouse),
        })
        .then((res) => {
          resolve(res);
          setConfirmReceivingResponse(res);
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };

  const getReceivingWarehouseCode = (receivingWarehouse) => {
    switch (receivingWarehouse) {
      case 'Peplink LT':
      case 'Peplink LT (Refurbished)':
        return 'peplink-lt';
      case 'Frontier US':
        return 'frontier-us';
      case 'West Network':
        return 'west-network';
      case 'MDG':
        return 'mdg';
      default:
        return '';
    }
  };

  const handleShowInputSnSkuModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: true,
      InputOtherInfoModal: false,
      ReceivingSummaryModal: false,
      ErrorMessageModal: false,
    }));
  };

  const handleShowOtherInfoModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: true,
      ReceivingSummaryModal: false,
      ErrorMessageModal: false,
    }));
  };

  const handleShowReceivingSummaryModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: false,
      ReceivingSummaryModal: true,
      ErrorMessageModal: false,
    }));
  };

  const handleShowErrorMessageModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: false,
      ReceivingSummaryModal: false,
      ErrorMessageModal: true,
    }));
  };

  return (
    <Modal isOpen={isOpenReceivingModal} toggle={toggleReceivingModal} returnFocusAfterClose={false} backdrop={false}>
      <>
        {isOpen.InputSnSkuModal && (
          <InputSnSkuModal
            isOpen={isOpen.InputSnSkuModal}
            onToggle={() => {
              toggleModal('InputSnSkuModal');
              toggleReceivingModal();
            }}
            sns={sns}
            setSns={setSns}
            rows={rows}
            setRows={setRows}
            handleShowOtherInfoModal={handleShowOtherInfoModal}
            handleShowErrorMessageModal={handleShowErrorMessageModal}
            validateSn={validateSn}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            formData={formData}
            validateSnResponse={validateSnResponse}
            setConfirmFormData={setConfirmFormData}
            searchSku={searchSku}
            warehouseType={warehouseType}
            setWarehouseType={setWarehouseType}
            isSupportRefurbished={isSupportRefurbished}
          />
        )}

        {isOpen.InputOtherInfoModal && (
          <InputOtherInfoModal
            isOpen={isOpen.InputOtherInfoModal}
            onToggle={() => {
              toggleModal('InputOtherInfoModal');
              toggleReceivingModal();
            }}
            formData={formData}
            setConfirmFormData={setConfirmFormData}
            handleInputChange={handleInputChange}
            skipUploadReceiving={skipUploadReceiving}
            setSkipUploadReceiving={setSkipUploadReceiving}
            handleShowInputSnSkuModal={handleShowInputSnSkuModal}
            handleShowReceivingSummaryModal={handleShowReceivingSummaryModal}
            warehouseType={warehouseType}
          />
        )}

        {isOpen.ReceivingSummaryModal && (
          <ReceivingSummaryModal
            isOpen={isOpen.ReceivingSummaryModal}
            setIsOpen={setIsOpen}
            onToggle={() => {
              toggleModal('ReceivingSummaryModal');
              toggleReceivingModal();
            }}
            confirmFormData={confirmFormData}
            handleShowOtherInfoModal={handleShowOtherInfoModal}
            handleShowErrorMessageModal={handleShowErrorMessageModal}
            onSubmitCreateReceiving={onSubmitCreateReceiving}
            toggleReceivingModal={toggleReceivingModal}
            warehouseType={warehouseType}
            isSupportRefurbished={isSupportRefurbished}
          />
        )}

        {isOpen.ErrorMessageModal && (
          <ErrorMessageModal
            isOpen={isOpen.ErrorMessageModal}
            onToggle={() => {
              toggleModal('ErrorMessageModal');
              toggleReceivingModal();
            }}
            validateSnResponse={validateSnResponse}
            confirmReceivingResponse={confirmReceivingResponse}
            handleShowInputSnSkuModal={handleShowInputSnSkuModal}
          />
        )}
      </>
    </Modal>
  );
};

const InputSnSkuModal = ({
  isOpen,
  onToggle,
  sns,
  setSns,
  rows,
  setRows,
  isLoading,
  setIsLoading,
  validateSn,
  handleShowOtherInfoModal,
  handleShowErrorMessageModal,
  formData,
  setConfirmFormData,
  searchSku,
  warehouseType,
  setWarehouseType,
  isSupportRefurbished,
}) => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState([false]);
  const [fetchedSkus, setFetchedSkus] = useState([[]]);
  const [selectedSkus, setSelectedSkus] = useState(['']);
  const debounceTimeoutRef = useRef(null);

  const handleNext = async (e) => {
    e.preventDefault();

    if (warehouseType == WAREHOUSE_TYPE.REFURBISHED) {
      setRows([{ sku: '', quantity: '' }]);
    }

    const emptySkus = rows.filter((row) => {
      return (
        (Array.isArray(row.sku) && row.sku.length === 0) ||
        (typeof row.sku === 'string' && !row.sku.trim() && row.quantity)
      );
    });
    const emptyQtys = rows.filter((row) => !row.quantity && row.sku?.trim());
    const filledRows = rows.filter((row) => (typeof row?.sku === 'string' && row.sku.trim()) || row.quantity);
    const invalidQtys = rows.filter((row) => (!row.quantity || row.quantity <= 0) && row.sku?.trim());

    if (warehouseType == WAREHOUSE_TYPE.REFURBISHED && sns.trim() === '') {
      setIsShowAlert(true);
      setAlertMessage('Serial Numbers cannot be empty');
      return;
    } else if (filledRows.length == 0 && sns.trim() === '') {
      setIsShowAlert(true);
      setAlertMessage('Serial Numbers and SKU cannot be both empty');
      return;
    } else if (warehouseType == WAREHOUSE_TYPE.REGULAR && emptySkus.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Please input the SKU');
      return;
    } else if (warehouseType == WAREHOUSE_TYPE.REGULAR && emptyQtys.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Please input the Qty');
      return;
    } else if (warehouseType == WAREHOUSE_TYPE.REGULAR && invalidQtys.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Qty cannot be less than or equal to 0');
      return;
    }

    setIsLoading(true);

    const response = await validateSn({
      sns: formData.sns,
      skus: formData.skuMaps ? formData.skuMaps.map((item) => item.sku) : [''],
      warehouseType: warehouseType.value,
    });
    const hasErrors = response?.items?.reduce((acc, obj) => acc || 'errors' in obj, false);

    setIsLoading(false);

    if (hasErrors) {
      handleShowErrorMessageModal();
    } else {
      const snMaps = response.items.map((item) => {
        const { sn, ...rest } = item;
        return {
          ...rest,
          sns: [sn],
          quantity: 1,
        };
      });

      const combinedSkuMaps = Array.from(
        formData.skuMaps
          ?.reduce((map, item) => {
            if (!map.has(item.sku)) {
              map.set(item.sku, { ...item, sns: [], quantity: +item.quantity });
            } else {
              const existingItem = map.get(item.sku);
              existingItem.quantity += +item.quantity;
            }
            return map;
          }, new Map())
          .values()
      );

      setConfirmFormData((prevData) => ({ ...prevData, productList: [...snMaps, ...combinedSkuMaps] }));

      handleShowOtherInfoModal();
    }
  };

  const handleTableInputChange = (e, index, column) => {
    const newRows = [...rows];
    newRows[index][column] = e.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { sku: '', quantity: '' }]);
    setDropdownOpen((prev) => prev.map(() => false));
    setDropdownOpen((prev) => [...prev, false]);
    setFetchedSkus((prev) => [...prev, []]);
    setSelectedSkus((prev) => [...prev, []]);
  };

  const removeRow = (index) => {
    setRows((prev) => prev.filter((_, rowIndex) => rowIndex !== index));
    setFetchedSkus((prev) => prev.filter((_, skuIndex) => skuIndex !== index));
    setSelectedSkus((prev) => prev.filter((_, skuIndex) => skuIndex !== index));
  };

  const toggleDropdown = (index, sku) => {
    if (sku !== '') {
      setDropdownOpen((prev) => prev.map((value, i) => (i === index ? !value : value)));
      if (!fetchedSkus[index]?.includes(sku)) {
        handleTableInputChange({ target: { value: selectedSkus[index] } }, index, 'sku');
      }
    }
  };

  const handleSelect = (sku, index) => {
    setSelectedSkus((prev) => prev.map((selectedSku, i) => (i === index ? sku : selectedSku)));
    handleTableInputChange({ target: { value: sku } }, index, 'sku');
  };

  const fetchSkus = async (inputSku, index) => {
    if (inputSku !== '') {
      const response = await searchSku(inputSku);
      setFetchedSkus((prev) => prev.map((item, i) => (i === index ? response.skus : item)));
      setDropdownOpen((prev) => prev.map((value, i) => (i === index ? true : value)));
    }
  };

  const handleSkuChange = (e, index, column) => {
    const value = e.target.value;
    handleTableInputChange(e, index, column);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchSkus(value, index);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '550px' }}
      scrollable={true}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>Create Receiving - SN / SKU</Label>
      </ModalHeader>

      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />

      <ModalBody
        style={{ minHeight: '350px', maxHeight: '600px', overflowY: 'auto', marginTop: '-15px', marginBottom: '10px' }}
      >
        <Form>
          {isSupportRefurbished && (
            <FormGroup>
              <Label for="sns" style={{ fontWeight: 'bold', fontSize: '15px' }}>
                Type
              </Label>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  style={{
                    background: 'transparent',
                    color: 'black',
                    border: '1px solid #efefef',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minWidth: '30%',
                    padding: '0.45rem 0.8rem',
                    boxShadow: 'none',
                  }}
                >
                  {warehouseType == WAREHOUSE_TYPE.REFURBISHED ? 'Refurbished' : 'New'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setWarehouseType(WAREHOUSE_TYPE.REGULAR)}>New</DropdownItem>
                  <DropdownItem onClick={() => setWarehouseType(WAREHOUSE_TYPE.REFURBISHED)}>Refurbished</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="sns" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Product(s) with SN
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>(Please input SN)</span>
            <Input type="textarea" id="sns" value={sns} onChange={(e) => setSns(e.target.value)} rows="5" />
          </FormGroup>

          {warehouseType !== WAREHOUSE_TYPE.REFURBISHED && (
            <>
              <hr
                style={{
                  height: '1px',
                  width: '100%',
                  display: 'block',
                  margin: '15px auto 10px auto',
                  overflow: 'hidden',
                }}
              />

              <FormGroup>
                <Table>
                  <thead style={{ borderBottom: 'none', borderTop: 'none' }}>
                    <tr style={{ marginBottom: '0px', paddingBottom: '0px', borderBottom: 'none', borderTop: 'none' }}>
                      <th style={{ fontSize: '15px', paddingBottom: '0px', paddingLeft: '0px' }}>
                        Product(s) without SN
                        <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray', fontWeight: 'normal' }}>
                          (Please input SKU and Qty)
                        </span>
                      </th>
                      <th style={{ fontSize: '15px', paddingBottom: '0px' }}>Qty</th>
                      <th style={{ width: '7%', paddingBottom: '0px' }}></th>
                    </tr>
                  </thead>
                  <tbody style={{ marginTop: '0px', paddingTop: '5px', paddingLeft: '0px' }}>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td style={{ width: '70%', paddingTop: '5px', paddingLeft: '0px' }}>
                          <div style={{ display: 'flex' }}>
                            <UncontrolledDropdown
                              toggle={() => toggleDropdown(index, row.sku)}
                              isOpen={dropdownOpen[index]}
                              direction="down"
                            >
                              <DropdownToggle
                                caret
                                style={{
                                  paddingLeft: '0px',
                                  paddingBottom: '0px',
                                  paddingTop: '0px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  color: 'black',
                                  boxShadow: 'none',
                                  background: 'transparent',
                                  border: '1px solid #efefef',
                                  width: '350px',
                                }}
                              >
                                <Input
                                  placeholder="Select SKU"
                                  type="text"
                                  value={row.sku}
                                  onChange={(e) => handleSkuChange(e, index, 'sku')}
                                  style={{
                                    borderColor: 'transparent',
                                    marginRight: '15px',
                                  }}
                                />
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ width: '100%', maxHeight: '200px', overflowY: 'auto', fontSize: '13px' }}
                                direction="down"
                                modifiers={{
                                  preventOverflow: { enabled: false },
                                  hide: { enabled: false },
                                  flip: { enabled: false },
                                }}
                              >
                                {fetchedSkus[index]?.map((sku, i) => (
                                  <div key={i}>
                                    <DropdownItem onClick={() => handleSelect(sku, index)}>{sku}</DropdownItem>
                                  </div>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </td>
                        <td style={{ width: '20%', paddingTop: '5px' }}>
                          <div style={{ display: 'flex' }}>
                            <Input
                              type="number"
                              value={row.quantity}
                              onChange={(e) => handleTableInputChange(e, index, 'quantity')}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'middle', paddingRight: '5px' }}>
                          {rows.length != 1 && (
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => removeRow(index)}
                              style={{ color: 'black', padding: '0', width: '20px', height: '30px' }}
                            >
                              <img
                                src="/images/minus.png"
                                alt="Remove"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                              />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  color="white"
                  size="sm"
                  onClick={() => addRow()}
                  style={{ color: 'black', padding: '0', width: '20px', height: '30px', marginTop: '-20px' }}
                >
                  <img
                    src="/images/add.png"
                    alt="Add"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Button>
              </FormGroup>
            </>
          )}
        </Form>

        {isShowAlert && <Alert color="danger">{alertMessage}</Alert>}
      </ModalBody>

      <ModalFooter style={{ paddingTop: '5px' }}>
        <Button color="secondary" onClick={() => onToggle()} style={{ color: 'black' }}>
          Cancel
        </Button>
        <Button color="primary" onClick={(e) => handleNext(e)}>
          Next
        </Button>
      </ModalFooter>

      {isLoading && (
        <>
          <Modal isOpen={isLoading}>
            <ModalBody style={{ padding: '20px', textAlign: 'center' }}>Loading...</ModalBody>
          </Modal>
        </>
      )}
    </Modal>
  );
};

const InputOtherInfoModal = ({
  isOpen,
  onToggle,
  formData,
  setConfirmFormData,
  handleInputChange,
  skipUploadReceiving,
  setSkipUploadReceiving,
  handleShowInputSnSkuModal,
  handleShowReceivingSummaryModal,
  warehouseType,
}) => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleNext = () => {
    if (formData.trackingNo.trim() == '') {
      setIsShowAlert(true);
      setAlertMessage('Tracking No. cannot be empty');
      return;
    } else if (formData.carrier.trim() == '') {
      setIsShowAlert(true);
      setAlertMessage('Carrier cannot be empty');
      return;
    }

    setConfirmFormData((prev) => ({
      ...prev,
      trackingNo: formData.trackingNo,
      carrier: formData.carrier,
      receivingWarehouse:
        warehouseType == WAREHOUSE_TYPE.REFURBISHED
          ? formData.receivingWarehouse + ' (Refurbished)'
          : formData.receivingWarehouse,
      skipUploadReceiving: formData.skipUploadReceiving,
      remarks: formData.remarks,
      warehouseType: warehouseType.value,
    }));

    handleShowReceivingSummaryModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '550px' }}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>
          Create Receiving - Other Info
        </Label>
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ maxHeight: '700px', overflowY: 'auto', marginTop: '-15px' }}>
        <Form>
          <FormGroup>
            <Label for="trackingNo" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Tracking No.
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>
              (Please provide tracking number if you have)
            </span>
            <Input type="text" id="trackingNo" value={formData.trackingNo} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup>
            <Label for="carrier" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Carrier
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>
              (Please provide carrier if you have)
            </span>
            <Input type="text" id="carrier" value={formData.carrier} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup>
            <Label for="receivingWarehouse" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Receiving Warehouse
            </Label>
            <Input
              type="text"
              id="receivingWarehouse"
              value={
                warehouseType == WAREHOUSE_TYPE.REFURBISHED
                  ? formData.receivingWarehouse + ' (Refurbished)'
                  : formData.receivingWarehouse
              }
              onChange={handleInputChange}
              disabled={true}
            />
          </FormGroup>

          {(formData.receivingWarehouse === 'MDG' || formData.receivingWarehouse === 'Frontier US') && (
            <FormGroup style={{ marginBottom: '5px' }}>
              <Input
                type="checkbox"
                checked={skipUploadReceiving}
                onChange={() => setSkipUploadReceiving(!skipUploadReceiving)}
                style={{ marginLeft: '0px' }}
              />
              <Label style={{ marginLeft: '20px', fontSize: '15px', fontWeight: 'bold' }}>
                Skip sending receiving API
              </Label>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="remarks" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Remarks
            </Label>
            <Input
              type="textarea"
              id="remarks"
              placeholder="Please provide any additional comments or notes"
              value={formData.remarks}
              onChange={handleInputChange}
              rows={3}
            />
          </FormGroup>
        </Form>

        {isShowAlert && <Alert color="danger">{alertMessage}</Alert>}
      </ModalBody>

      <ModalFooter>
        <Button
          id="inputOtherInfoModalBack"
          color="secondary"
          onClick={() => handleShowInputSnSkuModal()}
          style={{ color: 'black' }}
        >
          Back
        </Button>
        <Button color="primary" onClick={() => handleNext()}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ReceivingSummaryModal = ({
  isOpen,
  setIsOpen,
  onToggle,
  confirmFormData,
  handleShowOtherInfoModal,
  handleShowErrorMessageModal,
  onSubmitCreateReceiving,
  toggleReceivingModal,
  warehouseType,
  isSupportRefurbished,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const response = await onSubmitCreateReceiving();
    const hasErrors = Object.keys(response).includes('errors');

    if (hasErrors) {
      handleShowErrorMessageModal();
      setIsLoading(false);
    } else {
      setIsOpen((prevState) => ({
        ...prevState,
        InputSnSkuModal: false,
        InputOtherInfoModal: false,
        ReceivingSummaryModal: false,
        ErrorMessageModal: false,
      }));
      toggleReceivingModal();
      setIsLoading(false);
      toast.notify({
        type: 'info',
        value: 'Created receiving',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '700px' }}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>Create Receiving - Summary</Label>
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Product List</Label>
        <div style={{ maxHeight: '430px', overflowY: 'auto', marginBottom: '15px' }}>
          <Table borderless striped>
            <tbody>
              <tr key="columns">
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '55%' }}>SKU</td>
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '25%' }}>Serial No.</td>
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '20%' }}>Qty</td>
              </tr>

              {confirmFormData?.productList &&
                confirmFormData.productList.map((map, index) => {
                  return (
                    <tr key={index} style={{ fontSize: '13px' }}>
                      <td>{map.sku || '-'}</td>
                      <td>{Array.isArray(map.sns) && map.sns.length > 0 ? map.sns : '-'}</td>
                      <td>{map.quantity || '1'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <div>
          {isSupportRefurbished && (
            <p style={{ fontSize: '14px' }}>
              <b>{'Type: '}</b>
              {warehouseType == WAREHOUSE_TYPE.REFURBISHED ? 'Refurbished' : 'New'}
            </p>
          )}
          <p style={{ fontSize: '14px' }}>
            <b>{'Tracking No: '}</b>
            {confirmFormData.trackingNo ? confirmFormData.trackingNo : '-'}
          </p>
          <p style={{ fontSize: '14px' }}>
            <b>{'Carrier: '}</b>
            {confirmFormData.carrier ? confirmFormData.carrier : '-'}
          </p>
          <p style={{ fontSize: '14px' }}>
            <b>{'Receiving Warehouse: '}</b>
            {confirmFormData.receivingWarehouse ? confirmFormData.receivingWarehouse : '-'}
          </p>
          {(confirmFormData.receivingWarehouse === 'MDG' || confirmFormData.receivingWarehouse === 'Frontier US') && (
            <p style={{ fontSize: '14px' }}>
              <b>{'Skip sending receiving API: '}</b>
              {confirmFormData.skipUploadReceiving ? 'Yes' : 'No'}
            </p>
          )}
          <p style={{ fontSize: '14px' }}>
            <b>{'Remarks: '}</b>
            <br />
            {confirmFormData.remarks
              ? confirmFormData.remarks.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))
              : '-'}
          </p>
        </div>
      </ModalBody>

      <ModalFooter style={{ marginTop: '-20px' }}>
        <Button color="secondary" style={{ color: 'black' }} onClick={() => handleShowOtherInfoModal()}>
          Back
        </Button>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={(e) => {
            setIsLoading(true);
            onSubmit(e);
          }}
        >
          Submit
        </Button>
      </ModalFooter>

      {isLoading && (
        <>
          <Modal isOpen={isLoading}>
            <ModalBody style={{ padding: '20px', textAlign: 'center' }}>Loading...</ModalBody>
          </Modal>
        </>
      )}
    </Modal>
  );
};

const ErrorMessageModal = ({
  isOpen,
  onToggle,
  validateSnResponse,
  confirmReceivingResponse,
  handleShowInputSnSkuModal,
}) => {
  const errorTypes = [
    { key: 'SN_NOT_FOUND', label: 'SN Record Not Found.', field: 'sn' },
    { key: 'SN_WITH_EMPTY_SKU', label: 'Empty SKU In SN Record.', field: 'sn' },
    { key: 'SN_NOT_FOUND_IN_KD', label: 'SN Record Not Found In KD.', field: 'sn' },
    { key: 'SKU_NOT_FOUND_IN_KD', label: 'SKU Record Not Found In KD.', field: 'sn' },
    { key: 'INVALID_WAREHOUSE', label: 'SN Does Not Belong To The Warehouse "REFUR-LT"', field: 'sn' },
    { key: 'SN_ALREADY_USED', label: 'SN Is Already Used.', field: 'sn' },
    { key: 'DUPLICATED_SN', label: 'SN Is Duplicated.', field: 'sn' },
    { key: 'PROCESSING_RECEIVING', label: 'SN Is Processing Receiving.', field: 'sn' },
    { key: 'ALREADY_IN_STOCK', label: 'SN Is Already In Warehouse Inventory List.', field: 'sn' },
    { key: 'INVALID_SKU', label: 'SKU Is Invalid.', field: 'sku' },
    { key: 'SN_REQUIRED', label: 'SN Is Required For The Following SKU, Please Enter SN Instead.', field: 'sku' },
  ];

  const getErrorData = (key) => {
    const onValidateErrors = Array.isArray(validateSnResponse?.items)
      ? validateSnResponse?.items?.filter((obj) => obj.errors === key)
      : [];
    const onConfirmErrors = Array.isArray(confirmReceivingResponse?.errors)
      ? confirmReceivingResponse.errors.filter((obj) => obj.errors === key)
      : [];
    return onValidateErrors?.length ? onValidateErrors : onConfirmErrors?.length ? onConfirmErrors : [];
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop="static" returnFocusAfterClose={false} fade={false}>
      <ModalHeader>
        <span style={{ fontSize: '16px' }}>Invalid SN / SKU</span>
        <img
          src="/images/warning.png"
          alt="warning"
          style={{ marginBottom: '5px', marginLeft: '7px', width: '13px', height: '13px', objectFit: 'contain' }}
        />
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '12px auto',
          PaddingBottom: '0px',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ marginBottom: '-10px', paddingTop: '0px' }}>
        <Form>
          {errorTypes.map(({ key, label, field }) => {
            const errors = getErrorData(key);
            if (errors.length > 0) {
              return (
                <FormGroup key={key}>
                  <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>{label}</div>
                  <Input
                    type="textarea"
                    id={key}
                    placeholder=""
                    value={errors
                      .filter((error) => error[field])
                      .map((error) => error[field])
                      .join(', ')}
                    disabled
                    style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
                  />
                </FormGroup>
              );
            }
            return null;
          })}
          {errorTypes.every(({ key }) => !getErrorData(key).length) && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>
                An error occurred when creating receiving.
              </div>
              <Input
                type="textarea"
                id="unexpectedError"
                placeholder=""
                value={validateSnResponse?.errors || confirmReceivingResponse?.errors || 'Unexpected error'}
                disabled
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" style={{ color: 'black' }} onClick={handleShowInputSnSkuModal}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateReceivingModal;
