import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { formatDate, formatDateTime } from 'utils/formatters';
import { getReceivingProducts, getStatus } from 'utils/helpers';
import { inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SHIPMENT_STATUS } from 'definitions';
import { useForm } from 'react-hook-form';
import AssignQuantityModal from 'components/common/AssignQuantityModal';
import ConfirmSerialModal from 'components/common/ConfirmSerialModal';
import Loading from 'components/common/Loading';
import moment from 'moment';
import React, { useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import ReadSerialsModal from 'components/common/ReadSerialsModal';
import UpdateSerialModal from 'components/common/UpdateSerialModal';
import useToggleModal from 'utils/useToggleModal';

const ShipmentDetails = (props) => {
  const {
    receivingStore,
    receivingStore: {
      CRUD: { data: _data },
    },
    match: { params },
  } = props;
  const [readSerials, setReadSerials] = useState([]);
  const [confirmReceipts, setConfirmReceipts] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [totals, setTotals] = useState({ shipment: 0, received: 0 });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const ref = React.useRef();
  const methods = useForm();
  const { errors } = methods;
  const currentWarehouse = window.location.pathname.split('/')[2];
  const status = getStatus(_data.status, data);
  const isCompleted = status.value === SHIPMENT_STATUS.COMPLETED.value;
  const isClosed = status.value === SHIPMENT_STATUS.CLOSED.value;

  const { isOpen, toggleModal } = useToggleModal({
    confirmReceiptModal: false,
    readSerialModal: false,
    confirmSerialModal: false,
    assignQtyModal: false,
  });

  React.useEffect(() => {
    setData(_data.lines);
    setIsLoading(false);
  }, [_data]);

  const summary = React.useMemo(
    () =>
      data.reduce(
        (acc, value) => ({
          ...acc,
          total: acc.total + value.quantity,
          received: acc.received + value.receivedQuantity,
        }),
        { total: 0, received: 0 }
      ),
    [data]
  );

  React.useEffect(() => {
    const getTotalProducts = data.reduce(
      (acc, value) => ({ shipment: acc.shipment + value.quantity, received: acc.received + value.receivedQuantity }),
      { shipment: 0, received: 0 }
    );
    setTotals(getTotalProducts);
  }, [data]);

  React.useEffect(() => {
    setIsLoading(true);
    receivingStore.find(params.id, true);
  }, [params, receivingStore]);

  const handleUpdateReceiving = (items) => {
    return new Promise((resolve, reject) => {
      if (!items.length) return false;
      receivingStore
        .update({
          id: params.id,
          receiving_date: moment().format(),
          items: items.map(({ sku, quantity, sns }) => ({ sku, quantity, sns: sns || [] })),
        })
        .then(() => {
          receivingStore.find(params.id, true);
          resolve();
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };
  const handleConfirmSerialNumbers = (form) => {
    return new Promise((resolve, reject) => {
      const sns = form.sns
        .split('\n')
        .map((i) => i.trim())
        .filter((i) => i);
      receivingStore
        .update({
          id: _data.id,
          receiving_date: moment().format(),
          items: [{ sns, quantity: sns.length, sku: selected.sku }],
        })
        .then(() => {
          receivingStore.find(params.id, true);
          resolve();
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };
  const handleAssignQuantity = (form) => {
    return new Promise((resolve, reject) => {
      receivingStore
        .update({
          id: _data.id,
          receiving_date: moment().format(),
          items: [{ sns: [], quantity: form.quantity, sku: selected.sku }],
        })
        .then(() => {
          receivingStore.find(params.id, true);
          resolve();
        })
        .catch((err) => reject(err.data.errors));
    });
  };

  const handleReadSerialShowModal = React.useCallback(
    (selected) => {
      setReadSerials([...selected.receivedSns]);
      toggleModal('readSerialModal');
    },
    [toggleModal]
  );

  const handleConfirmSerialModal = React.useCallback(
    (selected) => {
      setSelected(selected);
      if (selected.sns.length) {
        toggleModal('confirmSerialModal');
      } else {
        toggleModal('assignQtyModal');
      }
    },
    [toggleModal]
  );
  const getUpdateSerialModalData = React.useCallback(() => {
    const { products, disabled } = getReceivingProducts(data);
    setConfirmReceipts([...disabled, ...products]);
  }, [data]);
  const handleConfirmReceiptShowModal = () => {
    toggleModal('confirmReceiptModal');
    getUpdateSerialModalData();
  };

  const handleGlobalFilter = (e) => {
    if (ref) {
      ref.current.setGlobalFilter(e.target.value);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Code',
        accessor: 'sku',
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        width: 30,
      },
      {
        Header: 'Shipment Quantity',
        accessor: 'quantity',
        className: 'text-center',
      },
      {
        Header: 'Received Quantity',
        accessor: 'receivedQuantity',
        className: 'text-center',
        Cell: ({ cell: { value, row } }) => (
          <React.Fragment>
            {row.original.quantity === value ? (
              value
            ) : isClosed ? (
              <span style={{ color: 'red' }}>{value}</span>
            ) : (
              <div className="badge-warn">
                <i className="material-icons md-warn md-18">warning</i>{' '}
                {`${value ? 'Partial Received' : 'Please Confirm'}`}
                {!!value && <span className="badge badge-qty">{`x${value}`}</span>}
              </div>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: ' ',
        width: 10,
        className: 'collapsed',
        Cell: ({
          cell: {
            row: { id, original },
          },
        }) => (
          <div className="action-icons d-flex justify-content-end">
            {original.receivedQuantity > 0 && original.sns.length > 0 && (
              <React.Fragment>
                <button
                  className="material-icons md-dark md-24"
                  id={`confirmed_sn-${id}`}
                  onClick={() => handleReadSerialShowModal(original)}
                >
                  search
                </button>
                <UncontrolledTooltip placement="top" target={`confirmed_sn-${id}`}>
                  Confirmed SN
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {original.quantity > original.receivedQuantity && !isClosed && (
              <React.Fragment>
                <button
                  className="material-icons md-dark md-24"
                  id={`confirm_receipt-${id}`}
                  onClick={() => handleConfirmSerialModal(original)}
                >
                  move_to_inbox
                </button>
                <UncontrolledTooltip placement="top" target={`confirm_receipt-${id}`}>
                  Confirm Receipt
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </div>
        ),
      },
    ],

    [handleConfirmSerialModal, handleReadSerialShowModal, isClosed]
  );

  return (
    <>
      {isLoading ? (
        <div className="d-flex min-vh-100">
          <Loading />
        </div>
      ) : (
        <div className="container-fluid p-md-5 p-3">
          <div className="breadcrumb">
            <h6>
              <Link to={`/inventory/${currentWarehouse}`}>Home</Link> {'>'}{' '}
              <Link to={`/shipment/${currentWarehouse}`}>Consignment Shipment</Link> {'>'} #{_data.waybill}
            </h6>
          </div>
          <div className="head-wrapper">
            <div className="row">
              <div className="head-title col-xl-7 col-lg-7 col-md-6 col-6">
                <h4>#{_data.waybill}</h4>
              </div>
              <div className="d-flex justify-content-end align-items-center col-xl-5 col-lg-5 col-md-6 col-6">
                <div className="label label_partial-ship mr-2">{status.label}</div>
                {!(isCompleted || isClosed) && (
                  <UncontrolledDropdown>
                    <DropdownToggle nav className="p-0">
                      <button
                        className="btn-action-menu material-icons md-light md-36"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        more_vert
                      </button>
                    </DropdownToggle>
                    <DropdownMenu right className="p-0 border">
                      <DropdownItem onClick={handleConfirmReceiptShowModal}>Confirm Receipt</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>
            </div>
          </div>
          <div className="main-panel mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm">
                  <div className="p-3 px-4">
                    <div className="row">
                      <div className="carrier col-xl-2 col-lg-4 col-md-6 col-12">
                        <div className="title">Carrier</div>
                        <div className="data">{_data.carrier}</div>
                      </div>
                      <div className="warehouse col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-0 mt-lg-0 mt-md-0 mt-3">
                        <div className="title">Warehouse</div>
                        <div className="data">{_data.warehouse}</div>
                      </div>

                      <div className="shipped-qty col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-0 mt-lg-0 mt-md-3 mt-3">
                        <div className="title">Total Shipment Quantity</div>
                        <div className="data">{totals.shipment}</div>
                      </div>

                      <div className="received-qty col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Total Received Quantity</div>
                        <div className="data">{totals.received}</div>
                      </div>

                      <div className="actual-ship-date col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Actual Ship Date</div>
                        <div className="data">{formatDate(_data.delivery_date)}</div>
                      </div>

                      <div className="blank col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div className="title" />
                        <div className="data" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-panel mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm">
                  <div className="p-3 px-4">
                    <div className="title">Products</div>
                    <div className="product-table mt-2">
                      <input
                        type="text"
                        className="form-control border-0"
                        placeholder="Search"
                        onChange={handleGlobalFilter}
                      />
                      <ReactTable isLoading={isLoading} data={data} columns={columns} ref={ref} />
                    </div>
                    <div className="total-products">Total: {data.length} record(s)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {_data.remarks != null && (
            <div className="remarks mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="card shadow-sm">
                    <div className="p-3 px-4">
                      <div className="title">Remarks</div>
                      <div className="content">
                        {_data.remarks?.split('\n').map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div id="footer" className="row my-5">
            <div className="created-date d-flex align-items-end col-6">
              Created on {formatDateTime(_data.created_date)}
            </div>
            <div className="confirm-action d-flex justify-content-end col-6">
              {!(isCompleted || isClosed) && (
                <button className="btn btn-primary btn-confirm" onClick={handleConfirmReceiptShowModal}>
                  Confirm Receipt
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {isOpen.confirmReceiptModal && (
        <UpdateSerialModal
          title={`#${_data.waybill}`}
          data={confirmReceipts}
          items={data}
          methods={methods}
          onToggle={() => toggleModal('confirmReceiptModal')}
          onDiscard={getUpdateSerialModalData}
          remaining={summary.total - summary.received}
          actions={(form, setErrors, handleDiscard, data) => {
            return (
              <React.Fragment>
                <Button color="dark" onClick={handleDiscard}>
                  Reset
                </Button>
                <Button
                  color="primary"
                  disabled={!isEmpty(errors)}
                  onClick={() => {
                    const quantities = data
                      .filter((i) => i.quantity)
                      .map(({ sku, quantity }) => {
                        return { sku, quantity };
                      });

                    handleUpdateReceiving([...form.items, ...quantities]);
                    toggleModal('confirmReceiptModal');
                  }}
                >
                  Confirm Receipt
                </Button>
              </React.Fragment>
            );
          }}
        />
      )}

      {isOpen.readSerialModal && (
        <ReadSerialsModal
          title="Received Serial Number(s)"
          items={readSerials}
          onToggle={() => toggleModal('readSerialModal')}
        />
      )}

      {isOpen.confirmSerialModal && (
        <ConfirmSerialModal
          onToggle={() => toggleModal('confirmSerialModal')}
          onSubmit={handleConfirmSerialNumbers}
          submitText="Confirm Receipt"
        />
      )}
      {isOpen.assignQtyModal && (
        <AssignQuantityModal
          selected={selected}
          remaining={selected.quantity - selected.receivedQuantity}
          data={data}
          onSubmit={handleAssignQuantity}
          onToggle={() => toggleModal('assignQtyModal')}
          label="Received Quantity"
          submitText="Confirm Receipt"
        />
      )}
    </>
  );
};

export default inject('receivingStore')(observer(ShipmentDetails));
